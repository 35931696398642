/* add css module styles here (optional) */

._3ybTi {
  margin: 2em;
  padding: 0.5em;
  border: 2px solid #000;
  font-size: 2em;
  text-align: center;
}

._3Tv81 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

._1-y3h {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

._2NVM0 {
  position: absolute;
  height: 2px;
  top: 55px;
  left: calc(-50% + 16px);
  right: calc(50% + 15px);
  background-color: #e4e8ebf5;
}
._3tNrZ {
  background-color: #de4290;
}

._2ZdKn {
  font-size: 24px;
  height: 40px;
  line-height: 40px;
}

._ZFVon {
  width: 25px;
  height: 25px;
  border: 3px solid #de4290;
  border-radius: 50%;
}

._3BlFF {
  background-color: #de4290;
  display: flex;
  justify-content: center;
  align-items: center;
}

._3wEtn {
  height: 15px;
  width: 15px;
}
